export default function Details() {
  return (
    <>
      <div className="bottom">
        <a
          href="https://beian.miit.gov.cn/"
          className="bottom-left"
          children="京ICP备20014020号-2"
        />
      </div>
      <span className="header-left">Saber<span style={{fontSize: '10px'}}>暂时还没想好首页来自zustand</span></span>
    </>
  )
}
